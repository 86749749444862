import React from "react";
/* import { useTranslation } from "react-i18next"; */
import { initReactI18next } from "react-i18next"; // Import initReactI18next
/* import CroatiaFlag from "../../Images/ostalo/Croatia-Flag.png";
import UKFlag from "../../Images/ostalo/UK-Flag.png"; */
import i18n from "i18next";

i18n.use(initReactI18next).init({
  resources: {
    /* *****HRVATSKI***** */
    hr: {
      translation: {
        // NAVIGATION
        navigation: {
          home: "NASLOVNA",
          projects: "PROJEKTI",
          contact: "KONTAKT",
        },
        // MAIN
        main: {
          hello: "Bok, moje ime je",
        },
        // ABOUT ME
        about: {
          title: "O meni",
          bio: "Imam 24 godine i živim u  Zagrebu. S strastću za stvaranje vizualno privlačnih i intuitivnih web stranica.",
          bio2: "Nedavno sam završio tečaj za Front-end developera na Visokom Učilištu Algebra.",
        },
        // CERTIFICATE
        certificate: {
          title: "Certifikati",
          view: "Pogledajte moje certifikate na LinkedInu.",
        },
        // SKILLS
        skills: {
          title: "Vještine",
        },
        // PROJECTS
        projects: {
          title: "Projekti",
          techUsed: "Korištene tehnologije:",
          live: "Uživo",
          code: "Kod",
          more: "Više projekata",
          // Chatapp
          chatappDescription1:
            "Wazapp ili izvorno chatapp je moj završni projekt za tečaj Front-End Developera na Visokom Učilištu Algebra",
          chatappDescription2:
            "U ovom projektu sam izgradio responsivnu chat aplikaciju povezanu s Scaledrone-om, s posebnim elementima poput nasumično generiranih imena i emotikona.",
          chatappDescription3:
            "Korisnici mogu uživati u personaliziranom doživljaju s mogućnošću prebacivanja između Dark/Light načina. Aplikacija je implementirana na Cloudflareu radi optimizirane izvedbe i sigurnosti..",
          // Autoconnect
          autoconnectDescription1:
            "Predstavljam Vam Autoconnect, moj prvi veliki projekt uživo gdje možete pregledavati automobile koji predstavljaju sam vrh trenutnih tehnoloških i dizajnerskih trendova...",
          autoconnectDescription2:
            "Dinamična, korisnički prijateljska i potpuno responsivna web stranica stvorena pomoću HTML-a, SCSS-a, JavaScript-a i Reacta.",
          // DonBosco
          donboscoDescription1:
            "Don Bosco - Podsused, novo dizajnirana web stranica crkve sa zelenom temom i modernog dizajna.",
          donboscoDescription2:
            "Dizajnirana za jednostavnu navigaciju i korisničko iskustvo. Sva važna informacija je na vrhu, osiguravajući da lako pronađete ono što Vam treba.",
          // WeatherApp
          weatherappDescription1:
            "Weather aplikaciju, mjesto gdje možete istraživati prognoze iz svih gradova diljem svijeta! Brzo saznajte trenutnu temperaturu, vlagu i nadolazeće vrijeme za narednih nekoliko dana.",
          weatherappDescription2:
            "Ova Weather aplikacija je potpuno responsivna. Bez obzira koristite li je na računalu ili mobilnom uređaju. Uživajte u brzom i lakom korištenju aplikacije.",
          nkPodsusedDescription1:
            "Dobrodošli na Web stranicu Nogometnog kluba Podsused! Ova platforma služi kao centralno mjesto za sve što se tiče našeg nogometnog kluba, pružajući sveobuhvatan pregled aktivnosti naše momčadi, povijesti, novosti i još mnogo toga.",
          nkPodsusedDescription2:
            "Ova Web stranica je napravljena za lako korištenje te korisnici mogu odabrati koji temu više volje, tamniju ili svijetliju.",
        },
        // KONTAKT
        contact: {
          title: "Kontaktirajte me",
          like: "Sviđa li Vam se moj rad?",
          touch: "Ostanimo u kontaktu, pošaljite mi e-mail :)",
        },
        // FORM
        form: {
          name: "Vaše ime:",
          namePH: "Vaše ime",
          message: " Vaša poruka:",
          messagePH: "Napišite poruku...",
          submit: "Pošalji",
          // Succesfull
          sentTitle: "Vaša poruka je poslana!",
          sentText: "Javit ću Vam se što je prije moguće.",
          // Error
          errorTitle: "Došlo je do pogreške!",
          errorText: "Pokušajte ponovno kasnije ili mi pošaljite e-mail.",
        },
        // FOOTER
        footer: {
          footerText: "Sva prava pridržana.",
        },
        // PRIVACY POLICY
        privacy: {
          date: "Datum stupanja na snagu:",
          title: "Politka Privatnosti",
          title1: "Pregled",
          text1:
            "Hvala vam što posjećujete Teov portfelj. Ova Pravila privatnosti objašnjavaju kako prikupljamo, koristimo, Vaše osobne informacije kada posjetite našu web stranicu.",
          title2: "Informacije koje prikupljamo",
          text2:
            "Možemo prikupiti osobne informacije koje nam dobrovoljno pružite   prilikom korištenja naše web stranice portfelja. To može uključivati vaše ime,   e-mail adresu i bilo koje druge informacije koje odlučite podijeliti.",
          title3: "Upotreba vaših informacija",
          text3:
            "Možemo koristiti prikupljene informacije za: Prilagodbu vašeg iskustva   na našoj web stranici Odgovaranje na vaše upite i pružanje podrške Unapređenje   naše web stranice na temelju vašeg povratnog informiranja Slanje periodičnih   e-mailova o ažuriranjima ili drugim relevantnim informacijama",
          title4: "Zaštita vaših informacija",
          text4:
            "Primjenjujemo različite sigurnosne mjere kako bismo očuvali sigurnost vaših osobnih informacija prilikom pristupa našoj web stranici.",
          title5: "Kolačići",
          text5:
            "Možemo koristiti kolačiće radi poboljšanja vašeg iskustva na našoj stranici. Možete   odabrati da vas računar upozori svaki put kad se kolačić šalje ili možete isključiti   sve kolačiće. Ako onemogućite kolačiće, neki dijelovi stranice mogu biti pogođeni,   ali stranica bi i dalje trebala biti funkcionalna.",
          title6: "Otkrivanje trećim stranama",
          text6:
            "Ne prodajemo, trgujemo ili na bilo koji drugi način prenosimo vaše osobne   identifikacijske informacije trećim stranama osim ako vam pružimo   prethodnu obavijest.",
          title7: "Poveznice trećih strana",
          text7:
            "Ponekad, prema našem nahođenju, možemo uključiti ili ponuditi proizvode   ili usluge trećih strana na našoj web stranici. Ove web stranice trećih strana   imaju zasebne i neovisne politike privatnosti. Stoga nemamo odgovornost   ili odgovornost za sadržaj i aktivnosti tih povezanih stranica.",
          title8: "Vaša suglasnost",
          text8:
            "Korištenjem naše web stranice, pristajete na naša Pravila privatnosti",
          title9: "Ažuriranje politike privatnosti",
          text9:
            "Možemo ažurirati Pravila privatnlsti s vremena na vrijeme. Sve promjene bit će objavljene na ovoj stranici.",
          title10: "Kontakt informacije",
          text10:
            "Ako imate bilo kakvih pitanja u vezi s ovim Pravilima privatnosti, možete nas kontaktirati na help.teoivanus@gmail.com .",
        },
        // TERMS OF USE
        terms: {
          title: "Opći uvijeti",
          date: "Datum stupanja na snagu:",
          title1: "Prihvaćanje uvjeta",
          text1:
            "Pristupanjem ili korištenjem web stranice Teoovog portfolija, slažete se pridržavati i biti vezani ovim Uvjetima korištenja. Ako se ne slažete s bilo kojim dijelom ovih uvjeta, molimo vas da ne koristite našu web stranicu.",
          title2: "Korištenje web stranice",
          text2:
            "Suglasni ste koristiti web stranicu Teoovog portfolija isključivo u zakonite svrhe. Zabranjeni ste kršiti ili pokušavati kršiti bilo koji sigurnosni element web stranice, uključujući, ali ne ograničavajući se na:",
          list1: "Neovlašteni pristup",
          list2: "Ometanje pravilnog funkcioniranja stranice",
          list3:
            "Bilo kakav pokušaj ispitivanja, skeniranja ili testiranja ranjivosti sustava ili mreže",
          title3: "Intelektualno vlasništvo",
          text3:
            "Svi sadržaji i materijali dostupni na Teovom portfoliju, uključujući, ali ne ograničavajući se na tekst, grafiku, logotipe, slike i softver, vlasništvo su Teoa i zaštićeni odgovarajućim autorskim i žigovskim pravom.",
          title4: "Korisnički prijenosi",
          text4:
            "Predajom bilo kojeg sadržaja (uključujući tekst, slike ili druge materijale) Teovom portfoliju, dajete Teou stalnu, neopozivu, svjetsku, besplatnu i neekskluzivnu licencu za reprodukciju, modificiranje, prilagodbu, objavljivanje, distribuciju, javno prikazivanje i inače korištenje takvog sadržaja u bilo koje svrhe.",
          title5: "Izjava o jamstvima",
          text5:
            "Teov portfelj pruža se kakav jest i bez jamstava bilo koje vrste, bilo izričitih ili implicitnih. Teo ne daje nikakve izjave ili jamstva o točnosti, potpunosti ili prikladnosti informacija i materijala na web stranici.",
          title6: "Ograničenje odgovornosti",
          text6:
            "Teo neće biti odgovoran za bilo kakvu izravnu, neizravnu, slučajnu, posljedičnu ili kaznenu štetu proizašlu iz vašeg pristupa ili korištenja Teovog portfolija.",
          title7: "Promjene Uvjeta korištenja",
          text7:
            "Teo može povremeno ažurirati ove Uvjete korištenja. Vaše nastavljeno korištenje web stranice nakon bilo kakvih promjena smatrat će se vašim prihvaćanjem takvih promjena.",
          title8: "Zakon koji se primjenjuje",
          text8:
            "Ovi Uvjeti korištenja podliježu zakonima i tumačenju prema zakonima Hrvatske.",
          title9: "Kontakt informacije",
          text9:
            "Ako imate bilo kakvih pitanja u vezi s ovim Uvjetima korištenja, možete nas kontaktirati na help.teoivanus@gmail.com .",
        },
      },
    },
    /* *****ENGLISH***** */
    en: {
      translation: {
        // NAVIGATION
        navigation: {
          home: "HOME",
          projects: "PROJECTS",
          contact: "CONTACT",
        },
        // MAIN
        main: {
          hello: "Hello, my name is",
        },
        // ABOUT ME
        about: {
          title: "About",
          bio: "I'am 24 years old based in Zagreb with a genuine passion for creating visually appealing and intuitive websites. ",
          bio2: "Recently I finished Front-end Developer course at Algebra University College.",
        },
        // CERTIFICATE
        certificate: {
          title: "Certificates",
          view: "View my certificates on LinkedIn.",
        },
        // SKILLS
        skills: {
          title: "Skills",
        },
        // PROJECTS
        projects: {
          title: "Projects",
          techUsed: "Technologies Used:",
          live: "Live",
          code: "Code",
          more: "More projects",
          // Chatapp
          chatappDescription1:
            "Wazapp or originaly chatapp is my final project for the Algebra University Front-End Developer course.",
          chatappDescription2:
            "In this project, I built a responsive chat application connected to Scaledrone, featuring custom elements like randomly generated names and emojis.",
          chatappDescription3:
            "Users can enjoy a personalized experience with a Dark/Light mode toggle. The application is deployed on Cloudflare for optimized performance and security.",
          // Autoconnect
          autoconnectDescription1:
            "Introducing Autoconnect, my first big live project where you can view cars that represent the very top of current technological and designs trends...",
          autoconnectDescription2:
            "A dynamic and user-friendly and fully responsive website created using HTML,SCSS, Java Script and React.",
          // DonBosco
          donboscoDescription1:
            "Don Bosco - Podsused, newly designed church website with a green theme and a modern design.",
          donboscoDescription2:
            "Designed for easy navigation and user-friendly experience. With all the important information at the top, ensuring to find what you need.",
          // WeatherApp
          weatherappDescription1:
            "Weather app, a place where you can explore forecasts from cities all around the world! Quickly find out the current temperature, humidity, and upcoming weather for the next few days.",
          weatherappDescription2:
            "This Weather app is fully responsive. Whether you use it on a computer or a mobile device, have a smooth and easy experience while navigating through the app.",
          nkPodsusedDescription1:
            "Welcome to the website of the Podsused Football Club! This platform serves as a central place for everything related to our football club, providing a comprehensive overview of our team's activities, history, news, and much more.",
          nkPodsusedDescription2:
            "This website is created for easy navigation, allowing users to choose their preferred theme, whether darker or lighter.",
        },
        // KONTAKT
        contact: {
          title: "Contact me",
          like: "Do you like my work?",
          touch: "Let's stay in touch, send me an email :)",
        },
        // FORM
        form: {
          name: "Your name:",
          namePH: "Your name",
          message: " Your message:",
          messagePH: "Write a message...",
          submit: "Submit",
          // Succesfull
          sentTitle: "Your message has been sent!",
          sentText: "I will contact you as soon as possible.",
          // Error
          errorTitle: "There has been an error!",
          errorText: "Try again later or send me an email.",
        },
        // FOOTER
        footer: {
          footerText: "All rights reserved.",
        },
        // PRIVACY POLICY
        privacy: {
          title: "Privacy policy",
          date: "Effective Date:",
          title1: "Overview",
          text1:
            "Thank you for visiting Teo's Portfolio. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information when you visit our website.",
          title2: "Information We Collect",
          text2:
            "We may collect personal information that you voluntarily provide to us when using our Portfolio website. This may include your name, email address, and any other information you choose to share.",
          title3: "Use of Your Information",
          text3:
            "We may use the information collected to: Personalize your experience on our website Respond to your inquiries and provide support Improve our website based on your feedback Send periodic emails regarding updates or other relevant information",
          title4: "Protection of Your Information",
          text4:
            "We implement a variety of security measures to maintain the safety of your personal information when you access our website.",
          title5: "Cookies",
          text5:
            "We may use cookies to enhance your experience on our site. You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. If you disable cookies, some features may be affected, but the site should still be functional.",
          title6: "Third-Party Disclosure",
          text6:
            "We do not sell, trade, or otherwise transfer your personally identifiable information to third parties unless we provide you with advance notice.",
          title7: "Third-Party Links",
          text7:
            "Occasionally, at our discretion, we may include or offer third-party products or services on our website. These third-party sites have separate and independent privacy policies. We, therefore, have no responsibility or liability for the content and activities of these linked sites.",
          title8: "Your Consent",
          text8: "By using our website, you consent to our Privacy Policy",
          title9: "Privacy policy update",
          text9:
            "We may update this changes to Our Privacy PolicyPrivacy Policy from time to time. Any changes will be posted on this page.",
          title10: "Contact Information",
          text10:
            "If you have any questions regarding this Privacy Policy, you may contact us at help.teoivanus@gmail.com .",
        },
        // TERMS OF USE
        terms: {
          title: "Terms Of Use",
          date: "Effective Date:",
          title1: "Acceptance of Terms",
          text1:
            "By accessing or using Teo's Portfolio website, you agree to comply with and be bound by these Terms of Use. If you do not agree with any part of these terms, please do not use our website.",
          title2: "Use of Website",
          text2:
            "You agree to use the Teo's Portfolio website for lawful purposes only. You are prohibited from violating or attempting to violate any security features of the website, including but not limited to:",
          list1: "Unauthorized access",
          list2: "Interference with the proper functioning of the site",
          list3:
            "Any attempt to probe, scan, or test the vulnerability of a system or network",
          title3: "Intellectual Property",
          text3:
            "All content and materials available on Teo's Portfolio, including but not limited to text, graphics, logos, images, and software, are the property of Teo and are protected by applicable copyright and trademark law.",
          title4: "User Submissions",
          text4:
            "By submitting any content (including text, images, or other materials) to Teo's Portfolio, you grant Teo a perpetual, irrevocable, worldwide, royalty-free, and non-exclusive license to reproduce, modify, adapt, publish, distribute, publicly display, and otherwise use such content for any purpose.",
          title5: "Disclaimer of Warranties",
          text5:
            "Teo's Portfolio is provided 'as is' and without warranties of any kind, either express or implied. Teo makes no representations or warranties about the accuracy, completeness, or suitability of the information and materials on the website.",
          title6: "Limitation of Liability",
          text6:
            "Teo will not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your access to or use of Teo's Portfolio.",
          title7: "Changes to Terms of Use",
          text7:
            "Teo may update these Terms of Use from time to time. Your continued use of the website after any changes will constitute your acceptance of such changes.",
          title8: "Governing Law",
          text8:
            "These Terms of Use are governed by and construed in accordance with the laws of Croatia.",
          title9: "Contact Information",
          text9:
            "If you have any questions regarding these Terms of Use, you may contact us at help.teoivanus@gmail.com.",
        },
      },
    },
  },
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default function Language() {
  /* const { t } = useTranslation(); */

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="language">
      <button onClick={() => changeLanguage("en")}>
        {/*  <img src={UKFlag} alt={t("UK Flag")} /> */}
        EN
      </button>
      <button onClick={() => changeLanguage("hr")}>
        {/*    <img src={CroatiaFlag} alt={t("Croatian Flag")} /> */}
        HR
      </button>
    </div>
  );
}
