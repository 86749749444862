import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Certificatess() {
  const { t } = useTranslation();
  return (
    <>
      <section className="section">
        <div className="title-box">
          <h2 className="title">{t("certificate.title")}</h2>
          <div className="line"></div>
        </div>
        <div className="certificates-box">
          <p>
            {t("certificate.view")}
            <Link
              to="https://www.linkedin.com/in/teo-ivanus-a5601028a/"
              className="link"
              target="_blank"
            >
              <i className="fi fi-brands-linkedin"></i>
            </Link>
          </p>
        </div>
      </section>
    </>
  );
}
