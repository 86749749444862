import WeatherAppImg from "../../Images/projects/weatherapp/WeatherApp.jpg";
import WeatherAppDesktop from "../../Images/projects/weatherapp/WeatherApp-Desktop.png";
import WeatherAppMobile from "../../Images/projects/weatherapp/WeatherApp-Mobile.png";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function AutoConnect() {
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="project">
        <img
          src={WeatherAppImg}
          alt="ChatApp"
          onClick={() => openImage(WeatherAppImg)}
        />
        <h2>Weather App</h2>
        <div className="project-btn-box">
          <Link to="https://weather-teo.pages.dev/" className="project-btn">
            {t("projects.live")}
          </Link>
          <Link
            to="https://github.com/TeoIvanus/Weather-App"
            className="project-btn"
          >
            {t("projects.code")}
          </Link>
        </div>
        <p>{t("projects.weatherappDescription1")}</p>
        <p>{t("projects.weatherappDescription2")}</p>
        <div className="projects-img">
          <img
            src={WeatherAppDesktop}
            alt="ChatApp"
            onClick={() => openImage(WeatherAppDesktop)}
          />
          <img
            src={WeatherAppMobile}
            alt="ChatApp"
            onClick={() => openImage(WeatherAppMobile)}
          />
        </div>
        <h4>{t("projects.techUsed")}</h4>
        <i className="fa-brands fa-html5"></i>
        <i className="fa-brands fa-css3-alt"></i>
        <i className="fa-brands fa-sass"></i>
        <i className="fa-brands fa-js"></i>
        <i className="fa-brands fa-react"></i>
      </div>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
    </>
  );
}
