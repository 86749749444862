/* import { Link } from "react-router-dom"; */
import TeoProfile from "../.././Images/logo/Teo.png";
import { useTranslation } from "react-i18next";

export default function Main() {
  const { t } = useTranslation();
  return (
    <>
      <main>
        <div className="left-side">
          <div className="name-box">
            <h5>{t("main.hello")}</h5>
            <div className="line"></div>
            <h1>Teo Ivanuš</h1>
            <h3>Front End Developer</h3>
            {/*     <Link to="#about">
              <i className="fi fi-rs-arrow-down"></i>
            </Link> */}
          </div>
        </div>
        <div className="right-side">
          <div className="profile-img-box">
            <img className="profile-photo" src={TeoProfile} alt="Teo Ivanuš" />
          </div>
        </div>
      </main>
    </>
  );
}
