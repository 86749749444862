import { useTranslation } from "react-i18next";

export default function AboutMe() {
  const { t } = useTranslation();
  return (
    <>
      <section className="section" id="about">
        <div className="title-box">
          <h2 className="title">{t("about.title")}</h2>
          <div className="line"></div>
        </div>
        <div className="AboutMe">
          <p>{t("about.bio")}</p>
          <p>{t("about.bio2")}</p>
        </div>
      </section>
    </>
  );
}

/*
 */
