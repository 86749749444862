// Images
import ChatAppImg from "../../Images/projects/chatapp/ChatApp.jpg";
import ChatAppMobile from "../../Images/projects/chatapp/ChatApp-mobile.png";
import ChatAppDesktop from "../../Images/projects/chatapp/ChatApp-desktop.png";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function ChatApp() {
  const { t } = useTranslation();
  const [showImage, setShowImage] = useState("");
  const openImage = (imageSrc) => {
    setShowImage(imageSrc);
  };
  const closeImage = () => {
    setShowImage("");
  };
  return (
    <>
      <div className="project">
        <img
          src={ChatAppImg}
          alt="ChatApp"
          onClick={() => openImage(ChatAppImg)}
        />
        <h2>Wazapp </h2>
        <div className="project-btn-box">
          <Link
            to="https://chatapp-teoivanus.pages.dev/"
            className="project-btn"
            target="_blank"
          >
            {t("projects.live")}
          </Link>
          <Link
            to="https://github.com/TeoIvanus/ChatApp-TeoIvanus"
            className="project-btn"
            target="_blank"
          >
            {t("projects.code")}
          </Link>
        </div>
        <p>{t("projects.chatappDescription1")}</p>
        <p>{t("projects.chatappDescription2")}</p>
        <p>{t("projects.chatappDescription3")}</p>

        <div className="projects-img">
          <img
            src={ChatAppDesktop}
            alt="ChatApp"
            onClick={() => openImage(ChatAppDesktop)}
          />
          <img
            src={ChatAppMobile}
            alt="ChatApp"
            onClick={() => openImage(ChatAppMobile)}
          />
        </div>
        <h4>{t("projects.techUsed")}</h4>
        <i className="fa-brands fa-html5"></i>
        <i className="fa-brands fa-sass"></i>
        <i className="fa-brands fa-js"></i>
        <i className="fa-brands fa-react"></i>
        <i className="fa-brands fi-brands-cloudflare"></i>
      </div>
      {/* *****SHOW IMAGES ON CLICK***** */}
      {showImage && (
        <div id="overlay" onClick={closeImage}>
          <div id="popup">
            <span id="close" onClick={closeImage}>
              &times;
            </span>
            <img
              id="popupImage"
              src={showImage}
              style={{ width: "100%", height: "100%" }}
              alt="Show"
            />
          </div>
        </div>
      )}
    </>
  );
}
