import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export default function Contact() {
  useEffect(() => {
    document.title = "Teo Ivanuš | Contact";
  }, []);
  const [isMessageSent, setMessageSent] = useState(false);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formData,
      });

      // Check if the form submission was successful
      if (response.ok) {
        setMessageSent(true);
      } else {
        const errorMessage = await response.text();
        setError(errorMessage);
      }
    } catch (error) {
      // Handle network or other errors
      setError("An error occurred. Please try again later.");
    }
  };

  return (
    <>
      <section className="section contact">
        <div className="title-box">
          <h2 className="title">{t("contact.title")}</h2>

          <div className="line"></div>
        </div>
        <h4>
          {t("contact.like")} <p>{t("contact.touch")}</p>
        </h4>
        {!isMessageSent ? (
          <form onSubmit={handleSubmit} className="contact-form" id="form">
            {/* <!-- Key --> */}
            <input
              type="hidden"
              name="access_key"
              value="f1e0e1f5-8e00-4401-ab44-2ee82c6909f3"
            />

            {/* <!-- From name --> */}
            <input type="hidden" name="from_name" value="Teo Contact" />
            {/* <!-- Subject name --> */}
            <input type="hidden" name="subject" value="Teo Portfolio" />

            <label htmlFor="name">{t("form.name")}</label>
            <input
              type="text"
              name="Ime i Prezime"
              placeholder={t("form.namePH")}
              id="name"
              autoComplete="name"
            />
            <label htmlFor="email">
              Email: <span>*</span>
            </label>
            <input
              type="email"
              name="email"
              id="email"
              autoComplete="name"
              placeholder="Email"
              required
            />

            <label htmlFor="message">
              {t("form.message")} <span>*</span>
            </label>
            <textarea
              name="Poruka"
              id="message"
              placeholder={t("form.messagePH")}
              required
            ></textarea>

            {/* <!-- Honeypot --> */}
            <input
              type="checkbox"
              name="botcheck"
              className="hidden"
              style={{ display: "none" }}
            />
            <input type="submit" value={t("form.submit")} id="submit-btn" />
          </form>
        ) : (
          <div className="thank-you">
            <h1>
              {t("form.sentTitle")}
              <i className="fi fi-ss-check-circle"></i>
            </h1>
            <p>{t("form.sentText")}</p>
          </div>
        )}
        {error && (
          <div className="error-box">
            <h1>
              {t("form.errorTitle")}
              <i class="fi fi-ss-exclamation"></i>
            </h1>
            <p>{t("form.errorText")}</p>
            <p>teoivanus00@gmail.com</p>
          </div>
        )}
      </section>
    </>
  );
}
