import { useTranslation } from "react-i18next";

export default function Skills() {
  const { t } = useTranslation();
  return (
    <>
      <section className="section">
        <div className="title-box">
          <h2 className="title">{t("skills.title")}</h2>
          <div className="line"></div>
        </div>
        <div className="skills">
          <i className="fa-brands fa-html5"></i>
          <i className="fa-brands fa-css3-alt"></i>
          <i className="fa-brands fa-sass"></i>
          <i className="fa-brands fa-js"></i>
          <i className="fa-brands fa-react"></i>
        </div>
      </section>
    </>
  );
}
