import React from "react";
import ReactDOM from "react-dom/client";
import "./Styles/App.scss";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  Contact,
  Footer,
  Language,
  MyProjects,
  Navigation,
  PrivacyPolicy,
  TermsOfUse,
} from "./Components";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Navigation />
    <Language />
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/projects" element={<MyProjects />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
    </Routes>
    <Footer />
  </BrowserRouter>
);
