import { Link } from "react-router-dom";
// Images
import AutoConnectImg from "../../Images/projects/autoconnect/Autoconnect.jpg";
import ChatAppImg from "../../Images/projects/chatapp/ChatApp.jpg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Projects() {
  const navigate = useNavigate();

  // Scroll to top of the page
  const handleClick = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };
  const { t } = useTranslation();
  return (
    <>
      <section className="section">
        <div className="title-box">
          <h2 className="title">{t("projects.title")}</h2>
          <div className="line"></div>
        </div>
        <div className="projects-grid">
          <ChatAppProject />
          <div className="project-border"></div>
          <AutoConnectProject />
        </div>
        <div className="more-btn-box">
          <Link
            className="more-btn"
            to="/projects"
            onClick={() => handleClick("/")}
          >
            {t("projects.more")} &rarr;
          </Link>
        </div>
      </section>
    </>
  );
}

function AutoConnectProject() {
  const { t } = useTranslation();
  return (
    <>
      <div className="project">
        <img src={AutoConnectImg} alt="ChatApp" />
        <div className="project-bio">
          <h3>AutoConnect</h3>
          <p>{t("projects.autoconnectDescription1")}</p>
        </div>
        <div className="project-used">
          <button>
            <i className="fa-brands fa-html5"></i>
          </button>
          <button>
            <i className="fa-brands fa-sass"></i>
          </button>
          <button>
            <i className="fa-brands fa-js"></i>
          </button>
          <button>
            <i className="fa-brands fa-react"></i>
          </button>
        </div>
        <div className="project-btn-box">
          <Link to="https://autoconnect.hr/" className="project-btn">
            {t("projects.live")}
          </Link>
          <Link
            to="https://github.com/TeoIvanus/Autoconnect"
            className="project-btn"
          >
            {t("projects.code")}
          </Link>
        </div>
      </div>
    </>
  );
}

function ChatAppProject() {
  const { t } = useTranslation();
  return (
    <>
      <div className="project">
        <img src={ChatAppImg} alt="ChatApp" />
        <div className="project-bio">
          <h3>ChatApp</h3>
          <p>{t("projects.chatappDescription1")}</p>
        </div>
        <div className="project-used">
          <button>
            <i className="fa-brands fa-html5"></i>
          </button>
          <button>
            <i className="fa-brands fa-sass"></i>
          </button>
          <button>
            <i className="fa-brands fa-js"></i>
          </button>
          <button>
            <i className="fa-brands fa-react"></i>
          </button>
        </div>
        <div className="project-btn-box">
          <Link
            to="https://chatapp-teoivanus.pages.dev/"
            className="project-btn"
            target="_blank"
          >
            {t("projects.live")}
          </Link>
          <Link
            to="https://github.com/TeoIvanus/ChatApp-TeoIvanus"
            className="project-btn"
            target="_blank"
          >
            {t("projects.code")}
          </Link>
        </div>
      </div>
    </>
  );
}
