import { NKPodsused, WeatherApp } from "..";
import AutoConnect from "./AutoConnect";
import ChatApp from "./ChatApp";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export default function MyProjects() {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = "Teo Ivanuš | Projects";
  }, []);
  return (
    <>
      <section className="section my-projects">
        <div className="title-box">
          <h2 className="title">{t("projects.title")}</h2>
          <div className="line"></div>
        </div>
        {/* ChatApp */}
        <ChatApp id="chatapp" />
        <div className="light-border"></div>
        {/* AutoConnect */}
        <AutoConnect id="autoconnect" />
        <div className="light-border"></div>
        {/* DonBosco */}
        {/*   <DonBosco /> */}
        {/* NK Podsused */}
        <NKPodsused />
        <div className="light-border"></div>
        <WeatherApp />
      </section>
    </>
  );
}
