import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();
  let year = new Date().getFullYear();
  return (
    <>
      <footer>
        <div className="footer-links">
          <Link className="link" to="/privacy-policy">
            {t("privacy.title")}
          </Link>
          <Link className="link" to="/terms-of-use">
            {t("terms.title")}
          </Link>
        </div>
        <p>
          TM and Copyright &copy; <span id="year">{year}</span> Teo Ivanuš.{" "}
          {t("footer.footerText")}
        </p>
        <Link
          to="https://www.linkedin.com/in/teo-ivanus-a5601028a/"
          className="social-link"
          target="_blank"
        >
          <i className="fi fi-brands-linkedin"></i>LinkedIn
        </Link>
        <Link
          to="https://github.com/TeoIvanus"
          target="_blank"
          className="social-link"
        >
          <i className="fi fi-brands-github"></i>GitHub
        </Link>
      </footer>
    </>
  );
}
