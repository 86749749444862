import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export default function PrivacyPolicy() {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = "Teo Ivanuš | Terms Of Use";
  }, []);
  return (
    <>
      <section className="section privacy">
        <div className="title-box">
          <h2 className="title">{t("terms.title")}</h2>
          <div className="line"></div>
        </div>
        <div className="privacy-terms">
          <h4>{t("terms.date")} 30.01.2024.</h4>
          <h3>{t("terms.title1")}</h3>
          <p>{t("terms.text1")}</p>
          <h3>{t("terms.title2")}</h3>
          <p>{t("terms.text2")}</p>
          <ul>
            <li>{t("terms.list1")}</li>
            <li>{t("terms.list2")}</li>
            <li>{t("terms.list3")}</li>
          </ul>
          <h3>{t("terms.title3")}</h3>
          <p>{t("terms.text3")}</p>
          <h3>{t("terms.title4")}</h3>
          <p>{t("terms.text4")}</p>
          <h3>{t("terms.title5")}</h3>
          <p>{t("terms.text5")}</p>
          <h3>{t("terms.title6")}</h3>
          <p>{t("terms.text6")}</p>
          <h3>{t("terms.title7")}</h3>
          <p>{t("terms.text7")}</p>
          <h3>{t("terms.title8")}</h3>
          <p>{t("terms.text8")}</p>
          <h3>{t("terms.title9")}</h3>
          <p>{t("terms.text9")}</p>
        </div>
      </section>
    </>
  );
}
