import { Certificates, Main, Projects, Skills } from "./Components";
import AboutMe from "./Components/AboutMe/AboutMe";
import "./Styles/App.scss";
import { useEffect } from "react";

export default function App() {
  useEffect(() => {
    document.title = "Teo Ivanuš";
  }, []);
  return (
    <>
      <Main />
      <AboutMe />
      <Certificates />
      <Skills />
      <Projects />
    </>
  );
}
