import { useTranslation } from "react-i18next";
import { useEffect } from "react";

export default function PrivacyPolicy() {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = "Teo Ivanuš | Privacy Policy";
  }, []);
  return (
    <>
      <section className="section privacy">
        <div className="title-box">
          <h2 className="title">{t("privacy.title")}</h2>
          <div className="line"></div>
        </div>
        <div className="privacy-terms">
          <h4>{t("privacy.date")} 30.01.2024.</h4>
          <h3>{t("privacy.title1")}</h3>
          <p>{t("privacy.text1")}</p>
          <h3>{t("privacy.title2")}</h3>
          <p>{t("privacy.text2")}</p>
          <h3>{t("privacy.title3")}</h3>
          <p>{t("privacy.text3")}</p>
          <h3>{t("privacy.title4")}</h3>
          <p>{t("privacy.text4")}</p>
          <h3>{t("privacy.title5")}</h3>
          <p>{t("privacy.text5")}</p>
          <h3>{t("privacy.title6")}</h3>
          <p>{t("privacy.text6")}</p>
          <h3>{t("privacy.title7")}</h3>
          <p>{t("privacy.text7")}</p>
          <h3>{t("privacy.title8")}</h3>
          <p>{t("privacy.text8")}</p>
          <h3>{t("privacy.title9")}</h3>
          <p>{t("privacy.text9")}</p>
          <h3>{t("privacy.title10")}</h3>
          <p>{t("privacy.text10")}</p>
        </div>
      </section>
    </>
  );
}
